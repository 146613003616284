













import Vue from 'vue';
import gql from 'graphql-tag';
export default Vue.extend({
  name: 'newcommentform',
  props: {
    itemName: {
      required: true,
    },
    parent: {
      default: '',
    },
  },
  data() {
    return {
      author: '' as string,
      message: '' as string,
      error: '' as string,
    };
  },
  methods: {
    messageParagraphs() {
      // return `<p>${this.message.replace(/\n(?=\b)/g, '</p><p>')}</p>`;
      return this.message.replace(/\n/g, '<br>');
    },
    createComment() {
      if (this.itemName === undefined ||
          this.itemName === null ||
          this.itemName === '') {
        throw new Error('name undefined');
      }
      // We save the user input in case of an error
      const newCommentAuthor = this.author;
      const newCommentMessage = this.message;
      const newCommentMessageParagraphs = this.messageParagraphs();
      // We clear it early to give the UI a snappy feel
      // this.newTag = ''
      this.author = '';
      this.message = '';
      this.error = '';

      // Call to the graphql mutation
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation($newcomment: NewComment!) {
          spirespyCreateComment(newcomment: $newcomment) {
            # parent
            # owner
            id
            # spam
            # body
            # thumbsup
            # author
            # createdAt
            # children {
            #   body
            #   pin
            #   id
            #   author
            #   createdAt
            # }
          }
        }`,
        // Parameters
        variables: {
          newcomment: {
            author: newCommentAuthor,
            owner: this.itemName,
            parent: this.parent,
            message: newCommentMessageParagraphs,
          },
        },

        // Update the cache with the result
        // The query will be updated with the optimistic response
        // and then with the real result of the mutation
        // update: (store, { data: { addTag } }) => {
        //   // Read the data from our cache for this query.
        //   const data = store.readQuery({ query: TAGS_QUERY })
        //   // Add our tag from the mutation to the end
        //   data.tags.push(addTag)
        //   // Write our data back to the cache.
        //   store.writeQuery({ query: TAGS_QUERY, data })
        // },

        // Optimistic UI
        // Will be treated as a 'fake' result as soon as the request is made
        // so that the UI can react quickly and the user be happy
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   addTag: {
        //     __typename: 'Tag',
        //     id: -1,
        //     label: newTag,
        //   },
        // },
      }).then((data) => {
        // Result
        console.log(data);
        this.onSuccess();
      }).catch((error) => {
        // Error
        console.error(error);
        // We restore the initial user input
        this.author = newCommentAuthor;
        this.message = newCommentMessage;
        this.error = error.message;
      });
    },
    onSuccess() {
      this.$emit('newcommentsuccess');
    },
  },
  computed: {
    validateName(): boolean {
      if (this.author.length < 3) {
        return false;
      }
      if (this.author.length > 20) {
        return false;
      }
      return true;
    },
    validateMessage(): boolean {
      if (this.message.length < 6) {
        return false;
      }
      if (this.message.length > 3000) {
        return false;
      }
      return true;
    },
    submitActive(): boolean {
      if (this.validateName && this.validateMessage ) {
        return true;
      }
      return false;
    },
  },
});
