

























import newcommentform from './commentcreateform.vue';

import Vue from 'vue';
export default Vue.extend({
  name: 'comment',
  components: { newcommentform },
  props: {
    commentData: {
      type: Object,
    },
    itemName: {
      required: true,
    },
    child: {
      default: false,
    },
  },
  data() {
    return {
      showReplyForm: false,
    };
  },
  methods: {
    onSuccess() {
      console.log(this.child);
      this.showReplyForm = false;
      this.$emit('newcommentsuccess');
    },
  },
});
