



















































































































































































































































































































































































import Vue from 'vue';
import gql from 'graphql-tag';
// import apollo from 'apollo';

import data from './carddata.json';
import card from './cardv2.vue';
import relic from './relic.vue';
import comment from './comment.vue';
import newcommentform from './commentcreateform.vue';
// import scattergraph from './graphs/scattergraph.vue';
// import heatmap from './graphs/heatmap.vue';
// import { Prop } from 'vue/types/options';

const cards: Card[] = data.cards;
const relics: Relic[] = data.relics;

interface Card {
  character: string;
  name: string;
  type: string;
  mana: number;
  manaplus: number;
  rarity: string;
  description: string;
  descriptionplus: string;
  supersynergies: number[];
  synergies: number[];
  antisynergies: number[];
  statement: string;
  ratios: [];
  index: number;
  beta: boolean;
}

interface Relic {
  rarity: string;
  description: string;
  character: string;
  name: string;
  supersynergies: number[];
  synergies: number[];
  antisynergies: number[];
  statement: string;
  ratios: [];
  index: number;
}

type Item = Card | Relic;

export default Vue.extend({
  name: 'item-detail',
  components: { card, relic, comment, newcommentform },
  props: {
    itemName: {
      type: String as () => string,
      required: true,
      // is already pure name [all lowercare, no -, no ']
    },
    itemType: {
      type: String as () => string,
      required: true,
    },
    isModal: {
      type: Boolean as () => boolean,
      default: () => false,
    },
  },
  data() {
    return {
      cardSortOrder: [
        'ironclad',
        'silent',
        'defect',
        'watcher',
        'colorless',
        'curse',
      ] as string[],
      blankCard: {
        character: 'curse',
        name: 'Error',
        type: 'curse',
        mana: -2,
        manaplus: -2,
        rarity: 'starter',
        description: `Oops! That doesn't exist.`,
        descriptionplus: '',
        supersynergies: [],
        synergies: [],
        antisynergies: [],
        statement: '',
        ratios: [],
        index: 0,
        beta: false,
      } as Card,
      blankRelic: {
        rarity: 'common',
        description: `Oops! That doesn't exist.`,
        character: 'any',
        name: 'Error',
        supersynergies: [],
        synergies: [],
        antisynergies: [],
        statement: '',
        ratios: [],
        index: 0,
      } as Relic,

      // Show All CardSynergies (not needed for relics?)
      supersynShowAllCharacters: false as boolean,
      synShowAllCharacters: false as boolean,
      antisynShowAllCharacters: false as boolean,

      // comments
      spirespyGetComments: [] as [],

      rerenderad: 0 as number,

      originalRoutePath: '' as string,
    };
  },
  methods: {
    thisCharacterFilter(itemArray: Item[]): any {
      return itemArray.filter((item) => {
        if (
          item.character === this.itemData.character ||
          item.character === 'colorless' ||
          item.character === 'curse'
        ) {
          return true;
        }
      });
    },
    showSeeAllButton(
      allCardsLength: number,
      filteredCardsLength: number,
    ): boolean {
      if (allCardsLength > filteredCardsLength) {
        return true;
      }
      return false;
    },
    sortFunc(a: any, b: any): any {
      if (
        this.synergyCardsSorter.indexOf(a.character) ===
        this.synergyCardsSorter.indexOf(b.character)
      ) {
        const x = a.name;
        const y = b.name;
        return x < y ? -1 : x > y ? 1 : 0;
      }
      return (
        this.synergyCardsSorter.indexOf(a.character) -
        this.synergyCardsSorter.indexOf(b.character)
      );
    },
    loadItem(type: string, name: string): void {
      if (this.isModal) {
        this.$emit('changeItemName', this.itemPureName(name), type);
        const el: HTMLElement | null = document.getElementById('top');
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        return;
      }
      this.$router.push({
        name: 'sls-itemdetail',
        params: { type, name: this.itemPureName(name) },
      });
    },
    itemPureName(name: string): string {
      return name
        .replace(/\s/g, '')
        .replace(/\'/g, '')
        .replace(/\-/g, '')
        .toLowerCase();
    },
    // metaDataName(){
    //   return this.itemData.name;
    // },

    onEscapeKeyUp(event: any): void {
      if (event.which === 27) {
        this.$emit('closeCardModal');
      }
    },
    commentSuccess(): void {
      // console.log('refetch');
      this.$apollo.queries.spirespyGetComments.refetch();
    },
    keyPlusOne(): void {
      this.rerenderad = this.rerenderad + 1;
    },
    // async incrementViewCountOnDatabase() {
    //   const itemName = this.itemData.name
    //   if (this.itemType === 'cards') {
    //     const res = await this.$apollo.mutate({
    //       mutation: gql`mutation ($name: String!){
    //         spireIncItemDetailViewCount(name: $name) {
    //           detailviews
    //         }
    //       }`,
    //       // Parameters
    //       variables: {
    //         name: itemName,
    //       },
    //     });
    //     return
    //   }
    //   // relics
    //   // console.log('relics');
    // },
    cardsArraySynergyCompiler(
      keyName: 'synergies' | 'supersynergies' | 'antisynergies',
      showAllCharacters: boolean,
    ): { showAll: boolean; itemList: Item[] } {
      const allCardsThatSynergise = cards.filter((cardItem: Item) =>
        this.itemData[keyName].includes(cardItem.index),
      );
      // if character is colorless or curse [cards only] then show all
      // if character is any [relics] then show all - this will show character relevant info on character only relics
      if (
        this.itemData.character === 'any' ||
        this.itemData.character === 'colorless' ||
        this.itemData.character === 'curse'
      ) {
        return {
          itemList: allCardsThatSynergise.sort(this.sortFunc),
          showAll: false,
        };
      }
      const filteredCards = this.thisCharacterFilter(allCardsThatSynergise);
      const areThereOtherCharacterCards = this.showSeeAllButton(
        allCardsThatSynergise.length,
        filteredCards.length,
      );
      const res = {
        showAll: areThereOtherCharacterCards,
        itemList: filteredCards.sort(this.sortFunc),
      };
      // if showallcharacters is true => show all
      if (showAllCharacters) {
        res.itemList = allCardsThatSynergise.sort(this.sortFunc);
      }
      return res;
    },
  },

  beforeMount() {
    if (this.isModal) {
      window.addEventListener('keyup', this.onEscapeKeyUp);
    }
  },
  mounted() {
    // on opening modal lock body
    // this.incrementViewCountOnDatabase()
    if (this.isModal) {
      this.$ga.page(
        this.$route.path + 'browse/' + this.itemPureName(this.itemName),
      );
      document.body.classList.add('scroll-lock');
      this.originalRoutePath = this.$route.path;
      window.history.replaceState(
        {},
        '',
        '/tools/slaythespire/' + this.itemType + '/' + this.itemName,
      );
    }
  },
  beforeDestroy() {
    // on closing modal unlock body
    if (this.isModal) {
      this.$ga.page(this.$route.path);
      document.body.classList.remove('scroll-lock');
      window.removeEventListener('keyup', this.onEscapeKeyUp);
      window.history.replaceState({}, '', this.originalRoutePath);
    }
  },
  computed: {
    itemData(): Item {
      let objectData;
      if (this.itemType === 'cards') {
        objectData = cards.find(
          (item: Item) => this.itemPureName(item.name) === this.itemName,
        );
      } else {
        objectData = relics.find(
          (item: Item) => this.itemPureName(item.name) === this.itemName,
        );
      }
      if (objectData === undefined) {
        // if item doesnt exist return blank data
        if (this.itemType === 'cards') {
          return this.blankCard;
        }
        return this.blankRelic;
      }
      return objectData;
    },
    superSynergyCardsData(): { showAll: boolean; itemList: Item[] } {
      return this.cardsArraySynergyCompiler(
        'supersynergies',
        this.supersynShowAllCharacters,
      );
    },
    synergyCardsData(): { showAll: boolean; itemList: Item[] } {
      return this.cardsArraySynergyCompiler(
        'synergies',
        this.synShowAllCharacters,
      );
    },
    antisynergyCardsData(): { showAll: boolean; itemList: Item[] } {
      return this.cardsArraySynergyCompiler(
        'antisynergies',
        this.antisynShowAllCharacters,
      );
    },
    synergyCardsSorter(): string[] {
      const sortOrder = [...this.cardSortOrder];
      const character = this.itemData.character;
      if (character === 'any') {
        return sortOrder;
      }
      const arrayPosition = sortOrder.indexOf(character);
      sortOrder.splice(arrayPosition, 1);
      sortOrder.unshift(character);
      return sortOrder;
    },
    // synergy
    superSynergyRelicsData(): Item[] {
      return relics.filter((relicItem: Item) =>
        this.itemData.supersynergies.includes(relicItem.index),
      );
    },
    synergyRelicsData(): Item[] {
      return relics.filter((relicItem: any) =>
        this.itemData.synergies.includes(relicItem.index),
      );
    },
    antisynergyRelicsData(): Item[] {
      return relics.filter((relicItem: any) =>
        this.itemData.antisynergies.includes(relicItem.index),
      );
    },
    commentsFailsafe(): [] {
      if (this.spirespyGetComments) {
        return this.spirespyGetComments;
      }
      return [];
    },
  },
  watch: {
    $route(): void {
      this.keyPlusOne();
    },
  },
  // Apollo-specific options
  apollo: {
    // Query with parameters
    spirespyGetComments: {
      // gql query
      query: gql`
        query($owner: String!, $limit: Int) {
          spirespyGetComments(owner: $owner, limit: $limit) {
            pin
            body
            id
            name
            owner
            createdAt
            children {
              body
              pin
              id
              name
              createdAt
            }
          }
        }
      `,
      // Static parameters
      variables() {
        return {
          owner: this.itemName,
        };
      },
      // update: data => this.data.itemComments
    } as any,
  },
});
