<template>
  <div class="relic-wrap">

    <div v-if="type === 'small'" class="relic">
      <img class="relicimg" :src="getImgUrl(relicImg)" alt="" @mouseenter="hover = true" v-on:mouseleave="hover = false">
      <p class="title">{{title}}</p>
      <div v-if="$route.path === '/tools/slaythespire/loadout'" class="card-button">
        <div v-if="browser && !comparator" @click="addRelicToDeck(title)"><p>Add Relic</p></div>
        <div v-if="comparator" @click="addRelicToDeck(title)"><p>Add To Deck</p></div>
        <div v-if="comparator" @click="removeRelic()"><p>Remove</p></div>
        <div v-if="!browser" @click="removeRelic()"><p>Remove</p></div>
      </div>
      <div class="tip" v-if="hover" @mouseenter="hover = true" v-on:mouseleave="hover = false">
        <div class="top"><img src="./../assets/ui/tipTop.png" alt=""></div>
        <div class="mid"><p class="midtitle">{{title}}</p></div>
        <div class="mid"><p>{{description}}</p></div>
        <div class="bot"><img src="./../assets/ui/tipBot.png" alt=""></div>
      </div>
    </div>

    <div class="relic-large" v-else>
      <img class="base" :src="getImgUrlSVG(relicBase)" alt="">
      <img class="image" :src="getImgUrl(relicImg)" alt="">
      <p class="title" :style="{ fontSize: titleRelativeFontSize() }">{{title}}</p>
      <p v-if="title !== 'Error'" class="rarity" :style="{ fontSize: rarityRelativeFontSize() }">{{rarity}}</p>
      <p v-else class="rarity" :style="{ fontSize: rarityRelativeFontSize() }">Error</p>
      <p class="description" :style="{ fontSize: descriptionRealtiveFontSize() }">{{description}}</p>
      <div v-if="$route.path === '/tools/slaythespire/loadout'" class="card-button">
        <div v-if="browser && !comparator" @click="addRelicToDeck(title)"><p>Add Relic</p></div>
        <div v-if="comparator" @click="addRelicToDeck(title)"><p>Add To Deck</p></div>
        <div v-if="comparator" @click="removeRelic()"><p>Remove</p></div>
        <div v-if="!browser" @click="removeRelic()"><p>Remove</p></div>
      </div>
      <div class="tier-wrap" >
        <div class="tier" v-for="character in tierCharactersToDisplay()" :key="`relic-tier-${character}`">
            <div v-if="tier(character) !== 'x' && tier(character) !== '' && tierShown(character)" class="character-block" :class="`${character}-color`"></div>
            <img class="background" :src="getImgUrlSVG(`cards/tier/${tier(character)}`)" :class="{tierX: !tierShown(character)}" alt="">
            <p v-if="tier(character) !== 'x' && tier(character) !== '' && tierShown(character)" :style="{ fontSize: tierRelativeFontSize }">{{tierText(character)}}</p>
            <!-- <img class="character" :src="getImgUrl(`ui/${character}Icon`)" alt=""> -->
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import allData from "./carddata.json";

export default {
  name: 'sls-relic',
  props: {
    rarity: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    character: {
      type: String,
      required: true,
    },
    // tierironclad: {
    //   type: String,
    //   default: 'F'
    // },
    // tiersilent: {
    //   type: String,
    //   default: 'S'
    // },
    // tierdefect: {
    //   type: String,
    //   default: 'A'
    // },
    tierCharacterSelected: {
      type: String,
      default: '',
    },
    comparator: { // Purely to prevent showing add card button on anything but the comparator
      type: Boolean,
      default: false,
    },
    browser: { // if cards are in the browser (true) / mydeck, comparator cards (false)
      type: Boolean,
      default: true,
    },
    mydeckIndex: { // if cards are in the browser (true) / mydeck, comparator cards (false)
      type: Number,
      // default: true,
    },
    type: {
      type: String,
      default: 'large'
    },
    elementIdForWidth: {
      type: String,
    }
  },
  data() {
    return {
      hover: false,
      relicWidthPx: 0,
      // allRelicsData: allData.relics
    }
  },
  computed: {
    relicImg() {
      const camelised = this.camelise(this.title);
      return `relics/${camelised}`;
    },
    relicBase() {
      return `relics/base/${this.rarity}`;
    },
    relicData(){
      const thisRelicData = allData.relics.find(relic => relic.name === this.title);
      return thisRelicData
    }
  },
  methods: {
    getImgUrl(url) {
      var images = require.context('../assets/', true, /\.png$/);
      return images('./' + url + '.png');
    },
    getImgUrlSVG(url) {
      var images = require.context('../assets/', true, /\.svg$/);
      return images('./' + url + '.svg');
    },

    camelise(str) {
      str = str.split('.').join('');
      str = str.split(`'`).join('');
      return str
        .replace(/(?:^\w|[A-Z\.]|\b\w)/g, function(letter, index) {
          return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
    addRelicToDeck(name) {
      this.$emit('addRelicInfo', name);
    },
    removeRelic() {
      this.$emit('removeRelic', this.mydeckIndex);
    },
    titleRelativeFontSize() {
      const baseFont = 48;
      const baseCardWidth = 546;
      const calc = this.relicWidthPx / baseCardWidth * baseFont;
      return calc + 'px';
    },
    rarityRelativeFontSize() {
      const baseFont = 30;
      const baseCardWidth = 546;
      const calc = this.relicWidthPx / baseCardWidth * baseFont;
      return calc + 'px';
    },
    descriptionRealtiveFontSize() {
      const baseFont = 36;
      const baseCardWidth = 546;
      const calc = this.relicWidthPx / baseCardWidth * baseFont;
      return calc + 'px';
    },
    tierRelativeFontSize() {
      const baseFont = 20;
      const baseCardWidth = 280;
      const calc = this.cardWidthPx / baseCardWidth * baseFont;
      return calc + 'px';
    },
    getCardWidth() {
        if (typeof this.elementIdForWidth === 'undefined') {
          this.relicWidthPx = document.getElementsByClassName('relic-large')[0].offsetWidth;
          return
        }
        this.relicWidthPx = document.getElementById(this.elementIdForWidth).offsetWidth;
    },
    tierCharactersToDisplay() {
      let characters;
      if (this.tierCharacterSelected) {
        characters = [this.tierCharacterSelected];
      } else {
        characters = ['ironclad', 'silent', 'defect', 'watcher'];
      }
      // console.log(characters);

      return characters
    },
    tier(character) {
      const tier = this.relicData[`tier${character}`]
      const letter = tier[0]
      let letterLowercase = letter

      if (letterLowercase) {
        letterLowercase = letter.toLowerCase()
      } else {
        letterLowercase = 'x'
      }
      // console.log("letter:" + letter);
      return letterLowercase
    },
    tierText(character) {
      const tier = this.relicData[`tier${character}`]
      // console.log(tier);
      return tier
    },
    tierShown(character) {
      const globalCharacterSelected = this.$route.params.character
      const relicCharacter = this.character;
      const isCharacterSelected = globalCharacterSelected !== undefined && globalCharacterSelected !== 'colorless'
      let showThisCharacterTierInfo = false;
      // console.log(isCharacterSelected);

      if (isCharacterSelected) {
        if (globalCharacterSelected === relicCharacter || globalCharacterSelected === character) {
          showThisCharacterTierInfo = true;
        }
      } else {
        showThisCharacterTierInfo = true;
      }
      // console.log(this.relicData.name + ' : ' + showThisCharacterTierInfo);

      return showThisCharacterTierInfo
    }
  },
  mounted() {
    window.addEventListener('resize', this.getCardWidth);
    this.getCardWidth();
    // setTimeout(() => {
    //   this.getCardWidth();
    // },50);
    setTimeout(() => {
      this.getCardWidth();
    },100);
    // setTimeout(() => {
    //   this.getCardWidth();
    // },200);
    setTimeout(() => {
      this.getCardWidth();
    },500);
    setTimeout(() => {
      this.getCardWidth();
    },2500);
  },
  updated() {
    this.getCardWidth();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getCardWidth);
  },
}
</script>

<style lang="scss" scoped>

// Large

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke*-1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}
/// Stroke font-character
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

.relic-large {
  position: relative;

  .base {
    // filter: drop-shadow( 0 0 0 rgba(#000, 0.4));
  }

  .title, .rarity, .description {
    text-align: center;
    // top: 12%;
    left: 50%;
    transform: translateX(-50%);
  }

  .title {
    position: absolute;
    top: 5%;
    text-shadow: 2px 2px 0 rgba(0,0,0,0.50);
  }

  .image {
    display: block;
    position: absolute;
    top: 13.5%;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    // filter: drop-shadow( 2px 2px 1px rgba(#000, 0.9));

  }

  .rarity {
    position: absolute;
    top: 55%;
    text-shadow: 2px 2px 0 rgba(0,0,0,0.50);
    text-transform: uppercase;
  }

  .description {
    position: absolute;
    top: 63%;
    text-shadow: 2px 2px 0 rgba(0,0,0,0.50);
    width: 74%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2em;
  }

  .tier-wrap {
    position: absolute;
    top: 11%;
    left: 0.5%;
    text-shadow: 2px 2px 0 rgba(0,0,0,0.50);
    width: 24%;
    // height: 42%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.2em;
    // transform: translateY(-50%);
  }

  .tier {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 94%;
    margin-bottom: -14%;
    position: relative;
    // height: 40px;
    // transition: 1s;
    .background {
      z-index: 10;
    }

    p {
      font-size: 20px;
      margin: 0;
      font-weight: bold;
      position: absolute;
      @include stroke(2,#4c4943);
      z-index: 10;
    }
    // .character {
    //   // position: absolute;
    //   top:50%;
    //   left: 1%;
    //   // transform: translateY(-50%);

    //   // width: 90%;
    //   z-index: 9;
    // }
    .character-block {
      position: absolute;
      top:50%;
      left: -8%;
      transform: translateY(-50%);
      height: 56%;
      // background: #000;
      box-shadow: 3px 1px 0 1px rgba(0,0,0,0.30);
      width: 60%;
    }
    .ironclad-color {
      background: #9C2B2E;
    }
    .silent-color {
      background: #3B9925;
    }
    .defect-color {
      background: #146CA6;
    }
    .watcher-color {
      background: #6b1980;
    }
    .tierX {
      // No rank for this character [not available]
      opacity: 0;
    }

    &:hover {

    }
  }

  .tier-s {
    background-image: url('./../assets/cards/tier/s.svg');
  }
  .tier-a {
    background-image: url('./../assets/cards/tier/a.svg');
  }
  .tier-b {
    background-image: url('./../assets/cards/tier/b.svg');
  }
  .tier-c {
    background-image: url('./../assets/cards/tier/c.svg');
  }
  .tier-d {
    background-image: url('./../assets/cards/tier/d.svg');
  }
  .tier-e {
    background-image: url('./../assets/cards/tier/e.svg');
  }
  .tier-f {
    background-image: url('./../assets/cards/tier/f.svg');
  }

  &:hover {
    .base {
      /* Use -webkit- only if supporting: Chrome < 54, iOS < 9.3, Android < 4.4.4 */
      // filter: drop-shadow( 0 0 20px rgba(#000, 0.9));
    }
  }
}



.relic {
  position: relative;
  text-align: center;
  width: 100%;
  &-wrap {
    display: flex;
    justify-content: flex-start;
    align-items:flex-start;
    // padding: 16px 5% 0;
    width: 100%;
    // width: 100%;
    // width: 340px;
    &:hover {
      .card-button {
        display: flex;
      }
    }
  }
}

.relicimg {
  width: 65%;
}

.title {
  display: block;
  width: 100%;
  font-size: 22px;
  font-weight: 400;
  margin-top: 0;
  opacity: 0.8;
}

.card-button {
  display: none;
  position: absolute;
  bottom: -4%;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  height: 16%;
  justify-content: center;
  cursor: pointer;
  > div {
    background: #000;
    width: 50%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;

    // padding-bottom: 8px;
    background: url('./../assets/ui/buttonL2.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);

    display: flex;
    &:hover {
      transform: scale(1.08,1.08);
      transition: 0s;
      z-index: 100;
      background: url('./../assets/ui/buttonHover2.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    > p {
      padding: 0;
      margin: 0 0 2px;
      font-size: 14px;
    }
  }
}

.tip {
  width: 260px;
  text-align: left;
  position: absolute;
  top: 28px;
  // transform: translateY(-50%);
  right: -200px;
  box-shadow: 8px 8px 0 0 hsla(0, 100%, 0%, 0.2);
  border-radius: 8px;
  z-index: 2;

  > .top {
    // background-image: url('./../assets/ui/tipTop.png');
    // width: 100%;
    height: 26px;
    overflow: hidden;
    margin: 0 0 -10px;
  }
  > .mid {
    background-image: url('./../assets/ui/tipMid.png');
    background-repeat: repeat-y;
    background-size: 100%;
    z-index: 1;
    position: relative;
    p {
      margin: 0;
      padding: 0 20px;
      line-height: 1.5rem;
      font-size: 1.1rem;
      text-shadow: 1px 1px 0 #1d1d18;
    }
    > .midtitle {
      color: #f0c944;
      font-weight: 500;
      font-size: 1.2rem;
      padding-bottom: 4px;
    }
  }
  > .bot {
    margin: -10px 0 0;
    height: 26px;
    overflow: hidden;
    // background: hsla(0, 100%, 0%, 0.0);
  }
}
</style>
